// extracted by mini-css-extract-plugin
export var topContainer = "mobile-about-module--topContainer--EALuu";
export var topContentContainer = "mobile-about-module--topContentContainer--mXJTE";
export var topTitle = "mobile-about-module--topTitle--8po+-";
export var topImageScrollContainer = "mobile-about-module--topImageScrollContainer--KDWgL";
export var topImage = "mobile-about-module--topImage--tiRAD";
export var strongPoint = "mobile-about-module--strongPoint--YGuto";
export var title = "mobile-about-module--title--727B0";
export var desc = "mobile-about-module--desc--yVpps";
export var featureContentContainer = "mobile-about-module--featureContentContainer--C7jEz";
export var featureTitle = "mobile-about-module--featureTitle--eIV-4";
export var featureDesc = "mobile-about-module--featureDesc--EqFX0";
export var featureRow = "mobile-about-module--featureRow--A3cyu";
export var featureRowTitle = "mobile-about-module--featureRowTitle--fLvIF";
export var featureContainer = "mobile-about-module--featureContainer--7Nd56";
export var featureCard = "mobile-about-module--featureCard--M-oi2";
export var featureIcon = "mobile-about-module--featureIcon--3Ro6Q";
export var featureCardTitle = "mobile-about-module--featureCardTitle--jLIIT";
export var featureCardContent = "mobile-about-module--featureCardContent--Ysg+P";