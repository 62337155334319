// extracted by mini-css-extract-plugin
export var topContainer = "desktop-about-module--topContainer--837oD";
export var topContentContainer = "desktop-about-module--topContentContainer--14BkQ";
export var topLeftContainer = "desktop-about-module--topLeftContainer--OADOD";
export var section = "desktop-about-module--section--80D0y";
export var strongPointDesc = "desktop-about-module--strongPointDesc--Yaa6G";
export var title = "desktop-about-module--title--4qvEe";
export var desc = "desktop-about-module--desc--2mjae";
export var featureContentContainer = "desktop-about-module--featureContentContainer--Ib2UT";
export var featureTitle = "desktop-about-module--featureTitle--wGijx";
export var featureDesc = "desktop-about-module--featureDesc--9CJ+S";
export var featureRow = "desktop-about-module--featureRow--3kSwB";
export var featureRowTitle = "desktop-about-module--featureRowTitle--g7qMS";
export var featureContainer = "desktop-about-module--featureContainer--zCY6i";
export var featureCard = "desktop-about-module--featureCard--1txtY";
export var featureCardBackground = "desktop-about-module--featureCardBackground--Q1q1B";
export var featureCardContentContainer = "desktop-about-module--featureCardContentContainer--OZ2Ri";
export var featureIcon = "desktop-about-module--featureIcon--YxCQ5";
export var featureCardTitle = "desktop-about-module--featureCardTitle--hdkVb";
export var featureCardContent = "desktop-about-module--featureCardContent--KijRR";